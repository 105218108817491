<template>
	<v-container fluid style="background-color: #F7B53F;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<img :src="require('@/assets/images/logo-region.png')" style="max-width: 250px; width: 100%;"/>

    <v-alert
		 	v-if="fromInvite"
      border="left"
      colored-border
			outlined
			dark
      type="info"
			class="mt-8 mb-0"
    >
			<div class="align-center justify-center text-center">
				<p class="mb-0 white--text">Para aceptar o rechazar la invitación que te han enviado debes ingresar a tu cuenta.</p>
				<p class="mb-0 white--text">Si no tienes una puedes registrarte en el botón "Registrar una cuenta" abajo.</p>
				<p class="mb-0 caption white--text">Luego de ingresar serás redirigido a la sección para aceptar o rechazar la invitación.</p>
			</div>
    </v-alert>
		<div class="login-form-container d-flex justify-center mt-8">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;"
			>
				<v-text-field
					v-model="email"
					:rules="emailRules"
					label="Email"
					required
					dark
				></v-text-field>
				<v-text-field
					v-model="password"
					autocomplete="off"
					name="pass"
					:rules="passwordRules"
					label="Contraseña"
					dark
					required
					validate-on-blur
					:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-btn
					:disabled="!valid"
					color="#5E85EF"
					dark
					:loading="loading"
					@click="startLogin"
				>
					Ingresar
				</v-btn>
				<router-link class="text-center mt-2" to="/resetpassrequest">¿Has olvidado tu contraseña?</router-link>

				<v-btn
					:disabled="!valid || loading"
					color="white"
					class="mt-4"
					small
					text
					@click="toRegister"
				>
					Registrar una Cuenta
				</v-btn>
				<v-btn
					:disabled="!valid || loading"
					color="white"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'index'})"
				>
					Volver al Inicio
				</v-btn>
			</v-form>
		</div>
	</v-container>
</template>

<script>
import { login } from '@/helpers/api/sosdenuncias/sosdenuncias'

export default {
	name: 'Login',
	data: () => ({
		forgottenPassword: false,
		valid: true,
		loading: false,
		showMessage: false,
		message: '',
		messageColor: 'error',
		email: '',
		emailRules: [
			v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		],
		password: '',
		passwordRules: [
			v => !!v || 'Contraseña es requerida',
			(v) => (v && v.length >= 8) || 'La contraseña debe ser de 8 caracteres mínimo'
		],
		passType: true,
		fromInvite: false
	}),
	mounted(){
		const query = this.$route.query.redirect_to
		this.fromInvite = query && query.includes('network_invite')
		console.log(this.$route);
	},
	methods: {
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		toRegister(){
			this.$router.push({ name: 'Register', query: this.$route.query })
		},
		async startLogin () {
			this.loading = true
			const loginResp = await login({email: this.email, password: this.password})
			this.loading = false
			
			if(loginResp.code === 200){
				if (loginResp['2fa_masked_user_phones']) {
					this.$store.commit('setLoginData', {email: this.email, password: this.password})
					this.$store.commit('setMaskedUserPhones', loginResp['2fa_masked_user_phones'])
					this.$router.push({ path: '/login2FA', query: { redirect_to: this.$route.query.redirect_to } })
					return
				}
				if(this.$route.query && this.$route.query.redirect_to){
					this.$router.push({ path: this.$route.query.redirect_to })
				}
				else{
					this.$router.push({ path: '/' })
				}
			}
			else{
				this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
				if(loginResp.code === 401){
					this.message = 'Lo sentimos, los datos ingresados no corresponden con nuestros registros.'
				}
				this.messageColor = 'warning'
				this.showMessage = true
			}
		},
	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}	
</style>
