<template>
	<v-container fluid style="background-color: #F7B53F;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0 text-center">{{message}}</p>
		</v-snackbar>
		<img :src="require('@/assets/images/logo-region.png')" style="max-width: 250px; width: 100%;"/>
		<div class="login-form-container d-flex justify-center mt-8">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;"
			>
				<v-text-field
					v-model="password"
					autocomplete="off"
					name="pass"
					:rules="passwordRules"
					label="Contraseña"
					dark
					required
					validate-on-blur
					:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-text-field
					v-model="password_confirmation"
					autocomplete="off"
					name="passconfirm"
					:rules="passwordConfirmRules"
					label="Confirmar Contraseña"
					dark
					required
					validate-on-blur
					:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>

				 <v-checkbox
				 style="margin: 12px auto;"
              v-model="revoke"
              label="Desconectar de todos los dipositivos"
              color="red darken-3"
              hide-details
            ></v-checkbox>
				<v-btn
					class="mt-3"
					:disabled="!valid"
					color="#5E85EF"
					dark
					:loading="loading"
					@click="resetPass"
				>
					Reestablecer Contraseña
				</v-btn>
				<v-btn
					:disabled="!valid || loading"
					color="white"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'index'})"
				>
					Ir al Inicio
				</v-btn>
			</v-form>
		</div>
	</v-container>
</template>

<script>
import {passwordReset, checkTokenPasswordReset} from '@/helpers/api/sosdenuncias/sosdenuncias'

export default {
	name: 'ResetPass',
	computed: {
		passwordConfirmRules() {
			return [
				(this.password_confirmation !== '') || 'Confirmación de contraseña es requerida',
				(this.password_confirmation === this.password) || 'Las contraseñas no coinciden'
			]
		}
	},
	data: () => ({
		valid: true,
		showMessage: false,
		message: '',
		messageColor: 'error',
		password: '',
		password_confirmation: '',
		passwordRules: [
			v => !!v || 'Contraseña es requerida',
			(v) => (v && v.length >= 8) || 'La contraseña debe ser de 8 caracteres mínimo'
		],
		loading: false,
		passType: true,
		token: null,
		revoke: true
	}),
	mounted(){
		this.token = this.$route.query.token
		this.checkTokenValidity(this.token)
	},
	methods: {
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		async checkTokenValidity(token){
			const tokenResp = await checkTokenPasswordReset({token})
			if(tokenResp.code !== 200){
				this.message = 'El token de validación no se encuentra válido, si aún lo necesitas, por favor solicita nuevamente reestablecer tu contraseña.'
				this.messageColor = 'warning'
				this.showMessage = true
				setTimeout(() => {
					this.$router.push({name: 'Login'})
				}, 6000)
			}
		},
		async resetPass () {
			this.loading = true
			const reset = await passwordReset({
				token: this.token,
				password: this.password,
				password_confirmation: this.password_confirmation,
				revoke: this.revoke
			})
			this.loading = false
			
			if(reset.code === 200){
				this.message = 'Se ha reestablecido la contraseña por favor ingresa en cualquiera app de la red SOSDenuncias con tu nueva clave.'
				this.messageColor = 'success'
				this.showMessage = true
				setTimeout(() => {
					this.$router.push({name: 'Home'})
				}, 4000)
			}
			else{
				this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
				this.messageColor = 'warning'
				this.showMessage = true
			}
		},
	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}	
</style>
